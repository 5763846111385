import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import logo from "./assets/logo.svg";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./LoginForm.css";

const styles = {};

class Loginform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      fields: {},
      errors: {},
      label: "",

    }

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }

  handleValidation(eml, pwd) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }
    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" })
    }

    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf('@');
      let lastDotPos = eml.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && eml.indexOf('@@') == -1 && lastDotPos > 2 && (eml.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email can not be empty" })
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { settings } = this.props;
    const toRegister = props => <Link to="../auth/register" {...props} />;
    const toResetPassword = props => <Link to="../auth/reset-password" {...props} />;
    return (
      <div className={"auth-form-cntr"}>
        <div><img src={settings.siteLogo.header} /></div>
        <form>
          <div className={"loginform-cntr"}>
            <div className="errorMsg">{this.state.label}</div>
            <FormControl
              fullWidth
              className={"input-cntr"}
            >
              <InputLabel htmlFor="adornment-password">Enter Registered Email ID</InputLabel>
              <Input
                id="user-email"
                label="Your Registered Email ID"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={"input-cntr"}
            >
              <InputLabel htmlFor="adornment-password">Enter Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                          <Visibility />
                        )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="errorMsg">{this.props.authMsg}</div>
            <FormControl className={"input-cntr"} >
              <Button className='forgot-pw' component={Link} to='/auth/reset-password'>Forgot Password</Button>
            </FormControl>
            <FormControl
              fullWidth
              className={"input-cntr"}
            >
              <Button
                className='button active' 
                onClick={() => {
                  let { email, password } = this.state;
                  let chkVal = this.handleValidation(email, password);

                  if (chkVal) {
                    this.props.loginAttempt({
                      email,
                      password,
                      successCallBack: () => {
                        //alert('success');
                      },
                      failureCallback: () => {
                        //alert('failed');
                      }
                    });
                  }
                  else {
                    this.setState({ label: "Either Email or Password is incorrect. Please try again." })
                  }
                }}
              >
                Login
              </Button>
            </FormControl>
            <FormControl
              fullWidth
              className={"input-cntr"}
            >
              <Button component={Link} to='/auth/register'>New User? Register</Button>
            </FormControl>
          </div>
        </form>
      </div>
    );
  }
}

export default Loginform;
