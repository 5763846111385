import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Auth from "./containers/auth";
import Public from "./containers/public";
import App from "./containers/app";

import "./Main.css";
import "./Typography.css";

let Main = class Main extends Component {
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          this.props.auth.isLoggedIn ? (
            <Component {...props} />
          ) : (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: props.location }
                }}
              />
            )
        }
      />
    );

    const PublicRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          !this.props.auth.isLoggedIn ? (
            <Component {...props} />
            // <Redirect
            //   to={{
            //     pathname: "/auth/login",
            //     state: { from: props.location }
            //   }}
            // />

          ) : (
              <Redirect
                to={{
                  pathname: "/app",
                  state: { from: props.location }
                }}
              />
            )
        }
      />
    );
    return <Switch>
      <Route path="/auth" component={Auth} />
      <PrivateRoute path="/app" component={App} />
      <PublicRoute exact path="/" component={Public} />
    </Switch>
  }
};

const mapStateToProps = state => ({ auth: state.auth });
export default withRouter(connect(mapStateToProps, null)(Main));
