import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import { signOut } from "../../containers/auth/redux_actions"
import "./header.css";

let Header = class Header extends Component {
  render() {
    let active = this.props.active;
    let { app } = this.props;
    let { auth } = this.props;
    return <AppBar position="static" color="transparent">
      <Toolbar>
        <div id="header-bar">
          <div className="site-logo-cntr">
            <div style={{
              height: '100%'
            }}><Link to={'/'}>
                <img height={40} alt='img-header' src={app.settings.siteLogo?app.settings.siteLogo.header:null} />
              </Link></div>
          </div>
          <div style={{ display: 'flex', fontWeight: 500 }}>
            {auth.isLoggedIn
              ? <div style={{ fontSize: 13, cursor: 'pointer' }} onClick={() => { this.props.signOut() }}>Logout</div>
              : <div style={{display: 'flex', alignItems: 'center'}}>
                <Hidden mdDown><Link style={{padding: '4px 20px', marginRight: 20}} className='button active' to='/auth/register'>Register</Link></Hidden>
                <Link className='button active text' to='/auth/login'>Sign in</Link>
              </div>
            }
          </div>
        </div>
      </Toolbar>
    </AppBar>
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ signOut }, dispatch);
}

export default connect(
  null, matchDispatchToProps
)(Header);

