import React from "react";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./Registration.css";

const styles = {};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      accesscode: "",
      fullname: ""
    };

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }
  render() {
    const { settings } = this.props;
    return <div className={"auth-form-cntr"}>
      <div><img src={settings.siteLogo.header} /></div>
      <form>
        <div className="registerform-cntr">
          <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <InputLabel htmlFor="adornment-password">Email</InputLabel>
            <Input
              id="user-email"
              label="Email"
              type="text"
              autoComplete="on"
              value={this.state.email}
              onChange={this.handleChange("email")}
            />
          </FormControl>
          <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <InputLabel htmlFor="adornment-password">Password</InputLabel>
            <Input
              id="user-password"
              label="Password"
              autoComplete="off"
              type={this.state.showPassword ? "text" : "password"}
              value={this.state.password}
              onChange={this.handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                        <Visibility />
                      )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <InputLabel htmlFor="adornment-password">Full name</InputLabel>
            <Input
              id="fullname"
              label="Full Name"
              type="text"
              value={this.state.fullname}
              onChange={this.handleChange("fullname")}
            />
          </FormControl>


          <div className="errorMsg" >{this.props.authMsg}</div>
          <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <Button
              className='button active'
              onClick={() => {
                let { email, password, accesscode, fullname } = this.state;
                this.props.registerAttempt({
                  email,
                  password,
                  accesscode,
                  fullname,
                  successCallBack: () => {
                    // console.debug("success");
                  },
                  failureCallback: () => {
                    // console.log("failed");
                  }
                });
              }}
            >
              Register
              </Button>
          </FormControl>
          <div className="login-link-cntr">
            <Link className='button active text forgot-pw-link' to={'/auth/reset-password'}>Forgot Password?</Link>
            <Link className='button active text login-link' to={'/auth/login'}>Already a user?</Link>
          </div>
        </div>
      </form>
    </div>
  }
}

export default Registration;
