import React, { useState, useEffect } from 'react';
import { Document, Page } from "react-pdf/dist/entry.webpack";
import Glide, { Controls } from '@glidejs/glide';

const leftArrow = <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#FFFFFF"
  width="24"
  height="36"
  viewBox="0 0 18 18"><path d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z" /></svg>
const rightArrow = <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#FFFFFF"
  width="24"
  height="36"
  viewBox="0 0 18 18"><path d="M7.5 4.5L6.44 5.56 9.88 9l-3.44 3.44L7.5 13.5 12 9z" /></svg>

const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;
const populatePages = (numPages, width, height, padding) => {
  let canvasWidth = width;
  let canvasHeight = height;
  console.log(canvasWidth);
  let array_pages = [];
  for (let i = 1; i <= numPages; i++) {
    array_pages.push(
      <li
        key={'pg-' + i}
        className='page-cntr glide__slide'>
          <Page
          pageNumber={i}
          width={canvasWidth}
          renderTextLayer={false}
          onRenderSuccess={() => { }} /></li>
    )
  };
  return array_pages;
}

let BookMultiPage = (props) => {
  const padding = 4;
  const { duringLoad, src, docRatio } = props;
  let [numPages, setNumpages] = useState(null);
  let [currentPg, setPageNumber] = useState(1);
  let [rotation, setRotation] = useState(false);
  let [width, setRootWidth] = useState(0);
  let [height, setRootHeight] = useState(0);


  let onDocumentLoadSuccess = (p) => {
    let { numPages } = p;
    setNumpages(numPages);
  }

  let cntr = window.document.getElementById('_book_root');
  let blk = window.document.getElementById('_book_meta_cntr');
  useEffect(() => {
    if (cntr) {
      let isDesktop = window.orientation == undefined;
      var w = cntr.clientWidth;
      var h = cntr.clientHeight;
      var deviceRatio = w / h;
      let flip = !isDesktop && !(deviceRatio > 1 && docRatio > 1) && !(deviceRatio < 1 && docRatio < 1);
      if (flip) {
        w = cntr.clientHeight; h = cntr.clientWidth;
        deviceRatio = w / h;
        let book = document.getElementById('_book-cntr');
        book.style.transform = 'rotate(90deg)';
        book.style.width = `${w}px`; book.style.height = `${h}px`;
        book.style.transformOrigin = `${h/2}px`;
      } else if(docRatio < deviceRatio) {
        h = cntr.clientHeight; w = cntr.clientHeight*docRatio;
      }
      setRootHeight(h); setRootWidth(w);
    }


    if (numPages > 0) {
      var glide = new Glide('.glide', { perView: 1 })
        .mount()
        .on('run', (ev) => {
          document
            .getElementById('current-page')
            .innerHTML = glide.index + 1;
        });
    }
  }, [cntr, numPages]);

  return (
    <div className='_book-cntr' id='_book-cntr'>
      <Document
        loading={duringLoad}
        className='document-cntr'
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}>
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {populatePages(numPages, width, height, padding)}
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls" style={{ width }}>
            <button className="glide__arrow glide__arrow--left" data-glide-dir={"<"}>{leftArrow}</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir={">"}>{rightArrow}</button>
          </div>
        </div>
        <div className="page-numbers">
          <span id='current-page'>1</span>/<span id='number-of-pages'>{numPages}</span>
        </div>
      </Document>
    </div>
  )
}

export default BookMultiPage;