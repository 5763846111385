import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './style.css';
import { Hidden } from '@material-ui/core';

export default () => {
  let app = useSelector((state) => state.app);
  let auth = useSelector((state) => state.auth);
  return <div style={{overflow: 'auto', height: '100%'}}>
    <Header app={app} auth={auth} />
    <Hidden mdDown><div className='public-hero desktop' style={{ backgroundImage: `url('${app.settings.homeHero}')` }}>
      <div className='text-box-desktop'>
      <h2 style={{}}>{app.settings.siteTitle}</h2>
        <div style={{  marginTop: 12, fontWeight: 500 }}>{app.settings.programTitle}</div>
        <p style={{ marginTop: 18, fontSize: 16 }}>{app.settings.siteDescription}</p>
        <div className='action-cntr' style={{ display: 'flex', marginTop: 16, justifyContent: 'space-between' }}>
          <Link style={{ padding: '0px 20px' }} className='button active' to='/auth/register'>Register</Link>
          <Link className='button active text' to='/auth/login'>Sign in</Link>
        </div>
      </div>
    </div>
    </Hidden>

    <Hidden smUp><div className='public-hero mobile' style={{ backgroundImage: `url('${app.settings.homeHero}')` }}>
      <h2 style={{ padding: '25px 20px', width: 210 }}>{app.settings.siteTitle}</h2>
    </div>
      <div className='text-box'>
        <div style={{ fontWeight: 500 }}>{app.settings.programTitle}</div>
        <p style={{ marginTop: 18, fontSize: 16 }}>{app.settings.siteDescription}</p>
        <div className='action-cntr' style={{ display: 'flex', marginTop: 16, justifyContent: 'space-between' }}>
          <Link style={{ padding: '0px 20px' }} className='button active' to='/auth/register'>Register</Link>
          <Link className='button active text' to='/auth/login'>Sign in</Link>
        </div>
      </div>
    </Hidden>
    <div className='leader-speak'></div>
    <div className='live-in'></div>
    <Footer />
  </div>
}