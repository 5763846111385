import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import store from "../../Redux_Store";

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyCT8IjRmfHcjegIRVBagJCcfmrH-AQVCd8",
    authDomain: "digitaltextbook.firebaseapp.com",
    databaseURL: "https://digitaltextbook.firebaseio.com",
    projectId: "digitaltextbook",
    storageBucket: "digitaltextbook.appspot.com",
    messagingSenderId: "875141465354",
    appId: "1:875141465354:web:03b00df08b9204d4ea66f3",
    measurementId: "G-S8HEFF7HC9"
  };
  firebase.initializeApp(config);
}
let db = firebase.database();

firebase
  .auth()
  .onAuthStateChanged((user) => {
    if (user) {
      db.ref('/units').on('value', snapshot=>{
        store.dispatch({ type: 'UPDATE_UNITS', units: snapshot.val()})
      });
      db.ref(`users/${user.uid}`).on('value', snapshot=>{
        store.dispatch({ type: 'UPDATE_USER', user: snapshot.val()})
      })
    } else {
      //console.log("no user");
    }
  });

