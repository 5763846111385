import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Dashboard from './dashboard';
import AccessCode from "./accessCode";
import { useSelector } from "react-redux";
import './redux_actions';
export default ()=>{
  let auth = useSelector((state) => state.auth);
  let app = useSelector((state) => state.app);
  let isSubscribed = Object.keys(app.user.mySubscriptions?app.user.mySubscriptions:{}).length;
  return <div style={{height:'100%', display:'flex', flexDirection:'column', overflow: 'auto'}}>
    <Header app={app} auth={auth} />
    {isSubscribed?<Dashboard />:<AccessCode uid={auth.user_uid} />}
    <Footer />
  </div>
}