import React from 'react';
import { useSelector } from "react-redux";
import { Hidden } from '@material-ui/core';

import UnitCard from "./../../components/UnitCard";
import "./dashboard.css"
export default (units) => {
  let app = useSelector((state) => state.app);

  return <div className='dashboard'>
    <Hidden mdDown>
      <div style={{ backgroundColor: "#F7F7F7", marginBottom: 24 }}>
        <div className="dashboard-banner" style={{
          display: "flex", paddingTop: 56, paddingBottom: 36,
          justifyContent: "space-between", margin: "0px 36px"
        }}>
          <div>
            <div className="ch-sub-title" style={{ paddingBottom: 8 }}>{app.settings.programTitle}</div>
            <div style={{ fontSize: 30, maxWidth: 450, fontWeight: 500, color: "#1D1D1D" }}>{app.settings.siteTitle}</div>
          </div>
          <div>
            <div style={{ display: "flex" }} className="dashboard-banner">
              <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", padding: "0px 12px" }}>
                <div style={{ fontSize: 12, width: 120, marginLeft: 16, display: "flex", alignItems: "center" }}>Exclusively for<br />{app.settings.client.title}</div>
                <div><img alt="gtu" src={app.settings.client.logo} height={56} /></div>
              </div>
              <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", padding: "0px 12px" }}>
                <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Delivered by<br />{app.settings.host.title}</div>
                <div><img alt="illumine-logo" src={app.settings.host.logo} height={56} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Hidden>
    <Hidden smUp><div className='public-hero mobile' style={{ backgroundImage: `url('${app.settings.homeHero}')` }}>
      <h2 style={{ padding: '25px 20px', width: 210 }}>{app.settings.siteTitle}</h2>
    </div></Hidden>

    <div className="section-header-blk">
      <div style={{ fontSize: 24, margin: '20px 20px 0px 20px', fontWeight: 500, color: '#344854' }}>Study Material</div>
      <div style={{ fontSize: 16, margin: '0px 20px 20px 20px', color: '#344854' }}>Prescribed course material for the Contributor Personaity Development Program for GTU</div>
    </div>
    <div className="card-cntr" style={{ margin: "auto", display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {Object.keys(app.units || {}).map(unitId => {
        let unit = app.units[unitId];
        unit.unitId = unitId;
        if (unit.isReady) {
          return <div key={unitId}>
            <UnitCard unit={unit} />
          </div>
        } return null;
      })}
    </div>
  </div>
}