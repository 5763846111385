import React, { useState } from 'react';
import {
  Input,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";

import { constants } from '../../constants';
function FirstTimeUserMsg({uid}) {
  const [accessCode, setAccessCode] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  
  return <div className='blk-cntr' style={{flexGrow: 1, display:'flex', alignItems: 'center', justifyContent:'center'}}>
    <div style={{ width: 420 }}>
      <div style={{fontSize: "1.5rem", fontWeight: 500}}>Welcome!</div>
      <div style={{fontSize: "1.5rem", fontWeight: 500}}>Your registration was successful.</div>
      <p style={{margin: "28px 0px", lineHeight:1.4}}>Please input the ACCESS CODE provided to you so we create your session list</p>
      <form>
        <FormControl
          fullWidth
          className="input-cntr"
        >
          <InputLabel htmlFor="access-code">Access Code</InputLabel>
          <Input
            id="access-code"
            label="Access Code"
            type="text"
            autoComplete="off"
            value={accessCode ? accessCode : ''}
            onChange={ev => { setAccessCode(ev.target.value) }}
          />
        </FormControl>
        <FormControl
          fullWidth
          className="input-cntr"
        >
          <Button
           className='button active'
            onClick={() => {
              // var myHeaders = new Headers();
              // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              fetch(`${constants.server}/access-code`, {
                method: 'POST',
                body: JSON.stringify( { accessCode, uid })
              }).catch(err => {
                console.log(err)
              }).then(response => {
                response
                  .json()
                  .then(response => { if(!response.success){setErrMsg(response.errMsg)} });
              });
            }}
          >
            <span style={{ color: "#FFF" }}>Unlock Sessions</span>
          </Button>
        </FormControl>
          <div>{errMsg}</div>
      </form>
    </div>
  </div>
}

export default FirstTimeUserMsg;