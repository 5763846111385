import React, {Component} from "react";
import "./style.css";
let Footer = class Footer extends Component {
  render() {
    return <div
    className='footer-cntr'
      style={{
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '2px solid #dddddd',
      padding: '20px',
    }}>
      <div className='copyright'>© 2020; Illumine. All Rights Reserved.</div>
      <div >
        <div className="policies-cntr">
          <a target="_blank" href="https://illumine.in/privacy-policy.html" rel="noopener noreferrer">Privacy Policy</a>
          <a target="_blank" href="https://illumine.in/cookie-policy.html" rel="noopener noreferrer">Cookie Policy</a>
          <a target="_blank" href="https://illumine.in/terms-of-use.html" rel="noopener noreferrer">Terms &amp; Conditions</a>
        </div>
      </div>
    </div>
  }
}

export default Footer;