import React from 'react';
import { Link } from 'react-router-dom';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';

import "./style.css";

export default ({unit}) => {

  let icon = ()=>{
      switch (unit.type) {
      case 'pdf':
        return <DescriptionOutlinedIcon />
      case 'av':
        return <OndemandVideoOutlinedIcon />
      case 'tool':
        return <BuildOutlinedIcon />
      case 'quiz':
        return <ContactSupportOutlinedIcon />
      default:
        console.log(`Sorry.`);
    }};
 
  return <Link to={`/app/resource/${unit.unitId}`}><div className="p_card">
    <div className="p_card-hero" style={{backgroundImage:`url(${unit.cardHero})`}}>
      {/* <div className="media-type">{icon()}</div> */}
    </div>
    <div className="p_card-body">
    <div className="p_card-subtitle">{unit.module}</div>
      <div className="p_card-title">{unit.title}</div>
      <div className="p_card-description">{unit.desc}</div>
    </div>
    <div className="p_card-footer">
      <div className="card-footer-wrapper">
        <div>{unit.toolType}</div>
        <div className="media-type">{icon()}</div>
        {/* <div className="active button">View</div> */}
      </div>
    </div>
    </div></Link>
}