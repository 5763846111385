import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";

import ApplicationHome from "./applicationDashboard";
import Unit from './unit'

let Application = ()=>{
    return <Switch>
      <Route path="/app/dashboard" component={ApplicationHome}/>
      <Route path="/app/resource/:resourceId" component={ Unit }/>
      <Route path="/app/" component={ApplicationHome}/>
    </Switch>
}

export default Application;