const INITIAL_STATE = {
  units: null,
  settings: {siteTitle:'', client:{}, host:{}},
  user:{mySubscriptions:{}}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "UPDATE_UNITS":
      return { ...state, units: action.units };
    case "UPDATE_SETTINGS":
      return { ...state, settings: action.settings };
    case "UPDATE_USER":
      return { ...state, user: action.user };
    default:
      return state;
  }
};
