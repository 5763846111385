import React, { Component } from 'react';
import { connect } from "react-redux";
import MultiPageTemplate from '../../../components/MultiPagePDFReader';
import DownloadablePDF from '../../../components/downloadable_pdf';
import VideoPlayer from '../../../components/VideoPlayer';

import './glide_core.css';
import './glide_theme.css';
import './book.css';
import './book-multipage.css';

// const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;
const duringLoad = <div className='loading-screen'>Loading...</div>

let Book = class Book extends Component {
  render() {
    let { resourceId } = this.props.match.params;
    let unit = this.props.app.units[resourceId];
    if (unit) { return serveTemplate(unit) }
    return <div>No Such Book exists</div>
  }
}

function serveTemplate(unit) {
  let src = unit.mediaUrl, docRatio = unit.docRatio || null;
  if (unit.type == 'pdf_downloadable') {
    return <DownloadablePDF src={src} />
  } else if (unit.type == 'av') {
    return <div style={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#000'}}>
      <div className='video-plr-cntr' style={{height:'90vh', width:'160vh'}}><VideoPlayer mediaUrl={src}/></div>
    </div>
  } else if(unit.type == 'pdf') {
    return <div id='_book_root'><div id='_book_meta_cntr' className='_book_meta_cntr'>
      <MultiPageTemplate src={src} duringLoad={duringLoad} docRatio={docRatio} />
    </div></div>
  } else 
    return null;
}
const mapStateToProps = state => ({ app: state.app });
export default connect(mapStateToProps, null)(Book);