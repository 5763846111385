import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";

import Registration from "../../components/RegistrationForm";
import { registerAttempt, setErrMsg, resetLoginPage } from "./redux_actions";


let Register = class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      firstname: "",
      lastname: "",
      name: "",
    };

    this.notLoggedIn = classes => {
      return (
        <div className="auth-cntr">
          <div className="register-artifact"></div>
          <Registration
            registerAttempt={this.props.registerAttempt}
            authMsg={this.props.auth.loginErrorMsg}
            settings={this.props.app.settings}
          />
        </div>
      );
    };

    this.loggedIn = (
      <Redirect
        to={{
          pathname: "/app"
        }}
      />
    );
  }

  componentDidMount() {
    //reset all the values in the state;
    this.props.resetLoginPage();
  }

  render() {
    //this.props.requestPermissionToNotify();
    const { classes } = this.props;
    if (
      this.props.location.pathname === "/channel" &&
      this.props.auth.isLoggedIn
    ) {
      return;
    } else if (this.props.auth.isLoggedIn) {
      return this.loggedIn;
    } else {
      return this.notLoggedIn(classes);
    }
  }
};


const mapStateToProps = state => ({auth: state.auth, app: state.app});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerAttempt,
      setErrMsg,
      //requestPermissionToNotify,
      resetLoginPage
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Register);
