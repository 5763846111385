import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { Component } from "react";
import Main from "./Main";
import { Provider } from "react-redux";
import { ThemeProvider } from '@material-ui/core/styles';
import { persistStore, persistCombineReducers } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./Redux_Store.js";
import "./App.css";
import theme from "./theme"
class App extends Component {
  render() {
    let persistor = persistStore(store);
    return <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading</p>
            </div>
          }
          onBeforeLift={() => {}}
          persistor={persistor}
        >
        <Router>
          <Main />
        </Router>
        </PersistGate>
      </Provider>
      </ThemeProvider>
  }
}

export default App;
